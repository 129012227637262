/* eslint-disable */
import { CalendarIcon, ChevronLeft, Pen, X } from 'lucide-react'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PlaceOrderBg from '../../assets/images/PlaceOrderBg.png'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Components/ui/tabs'
import { Label } from '../../Components/ui/label'
import { Input } from '../../Components/ui/input'
import { Button } from '../../Components/ui/button'
import { toCurrency } from '../../utils/CurrencyUtil'
import { Popover, PopoverContent, PopoverTrigger } from '../../Components/ui/popover'
import { useModal } from '../../hooks/use-modal-store'
import { NavbarContext } from '../../Provider/ShowNavbar'
import env from '../../env/env'
import { post } from '../../http/restservice'
import { userDetailsState } from '../LoginState'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { messageModalState } from '../../store/Feedback'
import { addNumberSuffix, addMonth, monthNames } from '../../utils/Util'
import MFSipMandate from './MFSipMandate'
import { Card, CardContent } from '../../Components/ui/card'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../Components/ui/alert-dialog'

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../../Components/ui/sheet'
import { BankDetails } from '../../store/AccountDetailsStore'
import { AccountBalance } from '@mui/icons-material'
import useMediaQuery from '../../hooks/useMediaQuery'

const MFPlaceOrder = () => {
  const location = useLocation()
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const [fundDetails] = useState(location?.state?.fundDetails)
  const sipDates = [3, 5, 10, 15, 22, 25]
  const navigate = useNavigate()
  const { onOpen } = useModal()
  const { displayNavbar } = useContext(NavbarContext)
  const [tabValue, setTabValue] = useState(location.state.destination || 'One-Time')

  const [selectedDay, setSelectedDay] = useState(5)
  const [userDetails] = useRecoilState(userDetailsState)
  const [oneTimeAmount, setOneTimeAmount] = useState(Math.floor(fundDetails?.minInvt) || 0)
  const [sipAmount, setSipAmount] = useState(Math.floor(fundDetails?.minSipAmount) || 0)
  const [isSIPLoading, setIsSIPLoading] = useState(false)
  const [isOneTimeLoading, setIsOneTimeLoading] = useState(false)
  const setMessageModalState = useSetRecoilState(messageModalState)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const clientCode = userData?.uid.split('-')[0]
  const [showMfSipMandatePopup, setShowMfSipMandatePopup] = useState(false)
  const [sipDate, setSipDate] = useState('')

  const [nextSipDay, nextSipMonth, nextSipYear] = sipDate.split('/')
  const [redirectURL, setRedirectURL] = useState('')
  const [callMandateAuthURL, setCallMandateAuthURL] = useState(false)

  const currentDate = new Date()
  const today = currentDate.getDate()
  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear()
  const nextDay1 = new Date()
  nextDay1.setDate(today + 1)
  const nextDay2 = new Date()
  nextDay2.setDate(today + 2)

  const { contents: bankDetails } = useRecoilValueLoadable(BankDetails)
  const [src, setSrc] = useState('')
  const handleError = () => {
    setSrc(null)
  }

  let ifscCode = bankDetails?.ifscCode
  const extractedBranch = ifscCode?.match(/^[A-Za-z]{4}/)[0]?.toLowerCase()
  const iconSrc = `${env.BANK_LOGOS}/${extractedBranch}/symbol.svg`
  useEffect(() => {
    setSrc(iconSrc)
  }, [iconSrc])

  const [mandateId, setMandateId] = useState('')
  const [timeLeft, setTimeLeft] = useState(120)

  const [mandateStatus, setMandateStatus] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [doXSIP, setDoXSIP] = useState(false)
  const [mandateRegResponse, setMandateRegResponse] = useState(null)

  useEffect(() => {
    const handlePolling = async () => {
      if (callMandateAuthURL && mandateId) {
        // Check if it's time to call the eMandateAuthURL
        if (timeLeft % 10 === 0 || timeLeft === 0) {
          try {
            const eMandateResponse = await getEMandateAuthURL(mandateId)

            if (eMandateResponse.Status == 100) {
              setTimeLeft(-10)
              handleSuccessfulEMandate(eMandateResponse)
              return
            }

            if (timeLeft < 0 && eMandateResponse.Status != 100) {
              handleFailedEMandate()
            }
          } catch (error) {
            console.error('Error in eMandateAuthURL:', error)
            handleFailedEMandate()
          }
        }

        // Countdown logic
        if (timeLeft > 0) {
          const timerId = setTimeout(() => {
            setTimeLeft((prevTime) => prevTime - 1)
          }, 1000)

          // Cleanup timeout
          return () => clearTimeout(timerId)
        }
      }
    }

    handlePolling() // Call the polling function
  }, [callMandateAuthURL, timeLeft])

  useEffect(() => {
    const startPolling = (mandateId) => {
      const pollingDuration = 600 // Total duration in seconds (10 minutes)
      const pollingInterval = 10 // Interval in seconds
      let timeLeft = pollingDuration

      // Start the polling
      const intervalId = setInterval(async () => {
        if (timeLeft <= 0) {
          clearInterval(intervalId) // Stop polling after 10 minutes
          return
        }

        console.log(`Time left: ${timeLeft} seconds`)

        // Execute polling logic at each 10-second interval
        try {
          const response = await getMandateDeatils(mandateId)

          if (response.isError) {
            setShowMfSipMandatePopup(false)
            setMessageModalState({
              message: 'Error occurred while fetching mandate details or processing e-Mandate',
              severity: 'error',
            })
            clearInterval(intervalId) // Stop polling on error
            return
          }

          if (!response.isError) {
            setMandateStatus(response.MandateDetails[0].status)
          }

          if (!response.isError && response.MandateDetails[0].status === 'UNDER PROCESSING') {
            setMandateStatus(response.MandateDetails[0].status)
            await handleXSIP(mandateId, response.MandateDetails[0].status)
            clearInterval(intervalId) // Stop polling on success
            return
          }

          timeLeft -= pollingInterval
        } catch (error) {
          console.error('Error in eMandateAuthURL:', error)
          handleFailedEMandate()
          clearInterval(intervalId) // Stop polling on error
          return
        }

        // Decrease time left
        timeLeft -= pollingInterval
      }, pollingInterval * 1000) // Convert interval to milliseconds

      return intervalId // Return interval ID for cleanup
    }

    let intervalId
    if (redirectURL) {
      intervalId = startPolling(mandateId, redirectURL)
    }

    // Cleanup function to clear interval on unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
        console.log('Interval cleared on unmount')
      }
    }
  }, [redirectURL, mandateId])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    const res = addMonth(today, selectedDay)
    setSipDate(res)
  }, [selectedDay, today])

  const placeOneTimeOrder = async () => {
    try {
      setIsOneTimeLoading(true)

      const payload = {
        orderType: 'PURCHASE',
        schemeCode: fundDetails?.bseSchemeCode,
        clientCode: clientCode.toUpperCase(),
        orderVal: oneTimeAmount,
      }

      const response = await post('mutualfundservice/api/bseStar/mfOrder/order', payload)

      setIsOneTimeLoading(false)

      if (response.isError) {
        setMessageModalState({
          message: response.response.error,
          severity: 'error',
        })
      }

      const orderNumberMatch = response.data.match(/ORDER NO: (\d+)/)

      const orderNumber = orderNumberMatch ? orderNumberMatch[1] : null

      if (response.status === 'SUCCESS' && orderNumber) {
        const paymentPayload = {
          clientCode: clientCode.toUpperCase(),
          orderNumber: orderNumber,
          totalAmount: oneTimeAmount,
          upiId: userData?.upiId,
        }

        navigate('/dashboard/mutual-funds/order-payment', {
          state: {
            paymentPayload,
            orderNumber,
          },
        })
      } else {
        setMessageModalState({
          message: response.data.responsestring,
          linkMessage: response.data.responsestring,
          severity: 'error',
        })
      }
    } catch (error) {
      console.log('🚀 ~ placeOneTimeOrder ~ error:', error)
    }
  }

  const placeSIPOrder = async () => {
    try {
      setIsSIPLoading(true)

      const payload = {
        clientCode: clientCode.toUpperCase(),
        amount: sipAmount.toString(),
        sipStartDate: sipDate,
        mandateType: 'UNIVERSAL',
      }

      const response = await post(
        'mutualfundservice/api/bseStar/mfUpload/mandateRegistration',
        payload,
      )

      if (response?.isError) {
        setMessageModalState({
          message: response?.response?.message,
          severity: 'error',
        })
        return
      }

      if (
        (response?.mandateStatus === 'NEW' || response?.mandateStatus === null) &&
        response?.status === 'SUCCESS'
      ) {
        setIsSIPLoading(true)
        setShowMfSipMandatePopup(true)
        setMandateId(response.mandateId)
        setCallMandateAuthURL(true)
      }

      if (
        response?.status === 'SUCCESS' &&
        response?.mandateStatus !== 'NEW' &&
        response?.mandateStatus !== null &&
        response?.alreadyExists
      ) {
        setMandateRegResponse(response)
        setIsOpen(true)
      }
    } catch (error) {
      setMessageModalState({
        message: 'Something went wrong. Please try again later.',
        severity: 'error',
      })
    }
  }

  // Helper function to handle existing mandate registration
  const handleXSIP = async (mandateId, status) => {
    const payload = {
      schemeCode: fundDetails.bseSchemeCode,
      clientCode: clientCode.toUpperCase(),
      installmentAmount: sipAmount,
      startDate: sipDate,
      mandateId: mandateId,
      mandateStatus: status,
    }

    try {
      const xSIPRegistration = await post(
        'mutualfundservice/api/bseStar/api/XSIPRegistration',
        payload,
      )
      console.log('🚀 ~ handleXSIP ~ xSIPRegistration:', xSIPRegistration)

      setShowMfSipMandatePopup(false)

      if (xSIPRegistration.status === 'SUCCESS') {
        onOpen('openPopup', {
          title: 'SIP Registration Successful! ',
          message:
            'SIP Registration Successful! 🎉 Your SIP registration has been completed successfully.',
          severity: 'success',
        })
      }
    } catch (error) {
      console.log('Error registering XSIP:', error)
    }
  }

  // Helper function to get eMandate Auth URL
  const getEMandateAuthURL = async (mandateId) => {
    const eMandatePayload = {
      clientCode: clientCode.toUpperCase(),
      mandateId,
      loopBackUrl: 'https://mywealth.fabits.com/dashboard/mutual-funds/place-order',
    }

    return await post('mutualfundservice/api/bseStar/mfWebService/eMandateAuthURL', eMandatePayload)
  }

  const getMandateDeatils = async (mandateId) => {
    const today = new Date()
    const formattedDate = today.toLocaleDateString('en-GB').replace(/\//g, '/')

    const payload = {
      fromDate: '01/10/2024',
      toDate: formattedDate,
      clientCode: clientCode.toUpperCase(),
      mandateId: mandateId,
    }

    return await post('mutualfundservice/api/bseStar/mfWebService/mandateDetails', payload)
  }

  const handleSuccessfulEMandate = (eMandateResponse) => {
    setRedirectURL(eMandateResponse.ResponseString)
    setCallMandateAuthURL(true)
    setIsSIPLoading(false)
  }

  // Helper function to handle failed eMandate response
  const handleFailedEMandate = () => {
    setShowMfSipMandatePopup(false)
    setMessageModalState({
      message:
        'The request could not be processed due to an unexpected issue, Please try again later.',
      severity: 'error',
    })
  }

  const handleXSIPConfirm = async () => {
    if (mandateRegResponse) {
      setIsOpen(false)
      const mandateDetails = await getMandateDeatils(mandateRegResponse.mandateId)
      await handleXSIP(mandateRegResponse.mandateId, mandateDetails.MandateDetails[0].mandateStatus)
      setDoXSIP(false)
      return
    }
  }

  function getFundReturn(fundPortfolio) {
    if (fundPortfolio?.threeYrRet != null && fundPortfolio?.threeYrRet != 0) {
      return `${fundPortfolio.threeYrRet.toFixed(2)}`
    } else if (fundPortfolio?.fiveYrRet != null && fundPortfolio?.fiveYrRet != 0) {
      return `${fundPortfolio.fiveYrRet.toFixed(2)}`
    } else if (fundPortfolio?.oneYrRet != null && fundPortfolio?.oneYrRet != 0) {
      return `${fundPortfolio.oneYrRet.toFixed(2)}`
    } else {
      return 0
    }
  }

  function getFundYear(fundPortfolio) {
    if (fundPortfolio?.threeYrRet != null && fundPortfolio?.threeYrRet != 0) {
      return `3Y`
    } else if (fundPortfolio?.fiveYrRet != null && fundPortfolio?.fiveYrRet != 0) {
      return `5Y`
    } else if (fundPortfolio?.oneYrRet != null && fundPortfolio?.oneYrRet != 0) {
      return `1Y`
    } else {
      return 0
    }
  }

  return (
    <>
      {showMfSipMandatePopup ? (
        <MFSipMandate
          isSIPLoading={isSIPLoading}
          setIsSIPLoading={setIsSIPLoading}
          setShowMfSipMandatePopup={setShowMfSipMandatePopup}
          redirectURL={redirectURL}
          setRedirectURL={setRedirectURL}
          mandateStatus={mandateStatus}
        />
      ) : (
        <div className='bg-white'>
          <div className='max-w-[1128px] mx-auto min-h-screen pb-12'>
            <div
              className='flex gap-x-1 items-center font-semibold cursor-pointer lg:py-2.5 p-4 lg:px-0'
              onClick={() => navigate(-1)}
            >
              <ChevronLeft className='h-5 w-5' />
              Place Order
            </div>

            <div className='max-w-[360px] mx-auto h-full flex flex-col gap-y-12 justify-between'>
              <div className='h-[170px] w-full bg-cover flex justify-center items-center p-2 z-0 relative'>
                <div
                  className='absolute inset-0 bg-cover w-full h-full'
                  style={{ backgroundImage: `url(${PlaceOrderBg})` }}
                />
                <div
                  className='absolute inset-0'
                  style={{
                    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                    // boxShadow: '0px 16px 24px rgba(65, 85, 141, 0.08)',
                  }}
                />
                <div className='flex items-center space-x-4 z-10'>
                  <img
                    src={`${env.MF_LOGOS_URL}/${fundDetails?.amcCode}.png`}
                    alt={fundDetails?.legalName}
                    className='w-12 h-12 border rounded-lg'
                  />
                  <div>
                    <h3
                      className='font-semibold text-sm leading-tight text-wrap w-48'
                      style={{ textWrap: 'pretty' }}
                    >
                      {fundDetails?.legalName
                        ? fundDetails?.legalName.replace(/Fund.*$/, 'Fund')
                        : fundDetails?.sName
                        ? fundDetails?.sName.replace(/Fund.*$/, 'Fund')
                        : fundDetails?.sname.replace(/Fund.*$/, 'Fund')}
                    </h3>
                    <p className='text-sm text-muted-foreground'>
                      {getFundYear(fundDetails)} returns{' '}
                      <span className='font-bold text-green-600'>
                        {getFundReturn(fundDetails)}%
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <Tabs defaultValue='One-Time' value={tabValue} onValueChange={setTabValue}>
                <TabsList className='mx-auto w-full'>
                  <TabsTrigger
                    className={`text-primary-200 text-xl font-light flex flex-col ${
                      tabValue === 'One-Time' && 'text-primary-700 font-semibold'
                    }`}
                    value='One-Time'
                  >
                    One-Time
                    <span
                      className={`h-2 w-2 rounded-full ${
                        tabValue === 'One-Time' ? 'bg-primary-700' : 'bg-transparent'
                      }`}
                    ></span>
                  </TabsTrigger>
                  <TabsTrigger
                    className={`text-primary-200 text-xl font-light flex flex-col ${
                      tabValue === 'SIP' && 'text-primary-700 font-semibold'
                    }`}
                    value='SIP'
                  >
                    SIP
                    <span
                      className={`h-2 w-2 rounded-full  ${
                        tabValue === 'SIP' ? 'bg-primary-700' : 'bg-transparent'
                      }`}
                    ></span>
                  </TabsTrigger>
                </TabsList>
                <TabsContent value='One-Time' className='h-full'>
                  <div className='flex flex-col justify-center items-center gap-1.5 mt-12'>
                    <Label htmlFor='amount' className='text-black-400 text-xs'>
                      Investment amount
                    </Label>
                    <Input
                      startAdornment='₹'
                      type='amount'
                      id='amount'
                      placeholder='Amount'
                      className='border-none outline-none text-center max-w-[150px] font-DMSans font-semibold text-3xl'
                      value={oneTimeAmount}
                      onChange={(e) => setOneTimeAmount(e.target.value)}
                    />
                    {oneTimeAmount < Number(fundDetails?.minInvt) && (
                      <p className='text-xs text-error-600 text-center'>
                        Minimum first investment amount is ₹ {toCurrency(fundDetails?.minInvt)}
                      </p>
                    )}
                  </div>

                  <div className='flex justify-center gap-x-2 mt-6'>
                    {[1000, 2000, 3000].map((btn) => (
                      <Button
                        size='simple'
                        className='px-3 py-[2px] rounded-[50px] bg-primary-50 hover:bg-primary-50 text-primary-500'
                        onClick={() => setOneTimeAmount((prev) => Number(prev) + btn)}
                      >
                        + {toCurrency(btn)}
                      </Button>
                    ))}
                  </div>

                  <div className='flex gap-x-2 mt-12'>
                    <Button
                      className='rounded-xl border flex-1'
                      variant='simple'
                      onClick={() => setTabValue('SIP')}
                    >
                      Add SIP
                    </Button>
                    <Button
                      className='rounded-xl flex-1'
                      loading={isOneTimeLoading}
                      onClick={() => placeOneTimeOrder()}
                    >
                      Invest One Time
                    </Button>
                  </div>
                </TabsContent>
                <TabsContent value='SIP'>
                  {' '}
                  <div className='flex flex-col justify-center items-center gap-1.5 mt-12'>
                    <Label htmlFor='amount' className='text-black-400 text-xs'>
                      Investment amount
                    </Label>
                    <Input
                      startAdornment='₹'
                      type='amount'
                      id='amount'
                      placeholder='Amount'
                      className='border-none outline-none text-center max-w-[150px] font-DMSans font-semibold text-3xl mx-auto'
                      value={sipAmount}
                      onChange={(e) => setSipAmount(e.target.value)}
                    />
                    {sipAmount < Number(fundDetails?.minSipAmount) && (
                      <p className='text-xs text-error-600 text-center'>
                        Minimum SIP amount is ₹ {sipAmount === 0 ? 100 : fundDetails?.minSipAmount}
                      </p>
                    )}
                  </div>
                  <div className='flex justify-center gap-x-2 mt-6'>
                    {[1000, 2000, 3000].map((btn) => (
                      <Button
                        size='simple'
                        className='px-3 py-[2px] rounded-[50px] bg-primary-50 hover:bg-primary-50 text-primary-500'
                        onClick={() => setSipAmount((prev) => prev + btn)}
                      >
                        + {toCurrency(btn)}
                      </Button>
                    ))}
                  </div>
                  <div className='mt-12'>
                    <Label className='text-sm text-black-300'>Select SIP Date</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant={'outline'}
                          className='pl-3 font-normal border-none bg-primary-50 rounded-lg w-full flex justify-between max-w-[360px] mx-auto'
                        >
                          <span className='text-primary-500 flex items-center gap-x-2'>
                            <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                            {addNumberSuffix(+selectedDay)} of every month
                          </span>

                          <Pen className='h-4 w-4 text-primary-500' />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className='p-0 w-auto rounded-full' align='center' side='top'>
                        <div className='flex gap-x-2 p-2'>
                          {sipDates.map((day) => (
                            <Button
                              className={`h-8 w-8 rounded-full inline-block ${
                                selectedDay !== +day
                                  ? 'bg-white border border-primary-50 text-black-400 hover:bg-primary-50'
                                  : 'text-white'
                              }`}
                              size='simple'
                              // disabled={
                              //   +day === today ||
                              //   +day === nextDay1.getDate() ||
                              //   +day === nextDay2.getDate()
                              // }
                              onClick={() => setSelectedDay(+day)}
                            >
                              {day}
                            </Button>
                          ))}
                        </div>
                      </PopoverContent>
                    </Popover>

                    {/* <div className='flex justify-between items-center w-full mt-6'>
                  <Label className='text-nowrap text-sm'>Add annual step-up</Label>
                  <Input className='max-w-[72px]' endAdornment='%' />
                </div> */}

                    <Card className='w-full max-w-md mx-auto mt-6'>
                      {/* <CardHeader>
                        <CardTitle className='text-base font-semibold'>SIP Information</CardTitle>
                      </CardHeader> */}
                      <CardContent className='space-y-4 p-3'>
                        <div className='flex justify-between'>
                          {/* <div className='flex flex-col'>
                            <p className='text-sm font-medium text-black-300'>First Instalment</p>
                            <p className='text-sm font-semibold text-black-400'>Initiated Now</p>
                          </div> */}
                          <div className='flex flex-col'>
                            <p className='text-sm font-medium text-black-300'>Next Instalment</p>
                            <p className='text-sm font-semibold text-black-400'>
                              {addNumberSuffix(+nextSipDay)} {monthNames[nextSipMonth - 1]}{' '}
                              {nextSipYear}
                            </p>
                          </div>
                        </div>
                        <ul className='list-disc pl-5 space-y-2 text-black-300 text-xs'>
                          {/* <li>Your 1st SIP instalment will be initiated now.</li> */}
                          <li>
                            All future SIP instalments will be paid on{' '}
                            {addNumberSuffix(selectedDay)} of every month. Dates may vary in case of
                            holidays.
                          </li>
                          {/* <li>
                            Future installments must start at least 30 days after the SIP creation
                            date, as per BSE guidelines.
                          </li> */}
                        </ul>
                      </CardContent>
                    </Card>
                  </div>
                  {/* <div className='my-6 flex justify-center'>
                <Button
                  variant='link'
                  size='simple'
                  className='text-primary-500 p-0 border-b border-dashed border-primary-500 rounded-none hover:no-underline'
                  onClick={() => onOpen('openMFBreakdown', { displayNavbar })}
                >
                  View detailed breakdown
                  <ChevronDown className='h-4 w-4 ml-1' />
                </Button>
              </div> */}
                  <div className='flex gap-x-2 mt-6'>
                    <Button
                      disabled={oneTimeAmount === 0 || !oneTimeAmount}
                      className='rounded-xl border flex-1'
                      variant='simple'
                      onClick={() => setTabValue('One-Time')}
                    >
                      Add One Time amount
                    </Button>
                    <Button
                      disabled={sipAmount < 100 || !sipAmount}
                      className='rounded-xl flex-1'
                      loading={isSIPLoading}
                      onClick={() => placeSIPOrder()}
                    >
                      Start SIP
                    </Button>
                  </div>
                </TabsContent>
              </Tabs>
            </div>
          </div>
        </div>
      )}

      {isDesktop ? (
        <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
          <AlertDialogContent className='min-h-[430px] flex flex-col gap-6'>
            <AlertDialogHeader className={'gap-6'}>
              <AlertDialogTitle>
                <div className='relative justify-between'>
                  <div className='text-start'>
                    <h1 className='text-black-500 font-semibold text-2xl font-DMSans'>
                      Confirm SIP setup
                    </h1>
                  </div>
                  <div
                    className='cursor-pointer absolute right-0 top-0'
                    onClick={() => {
                      setIsOpen(false), setIsSIPLoading(false)
                    }}
                  >
                    <X className='h-5 w-5 ' /> <span className='sr-only'>Close</span>
                  </div>
                </div>
                <p className='text-black-400 font-normal text-base text-start font-DMSans'>
                  We will use your preexisting mandate to set up your new SIP.
                </p>
              </AlertDialogTitle>
              <AlertDialogDescription className='text-start flex flex-col gap-6'>
                <div className='flex border rounded-[16px] justify-between p-4'>
                  <div className='flex gap-2'>
                    <img
                      src={`${env.MF_LOGOS_URL}/${fundDetails?.amcCode}.png`}
                      alt={fundDetails?.amcName}
                      className='w-12 h-12 border rounded-lg'
                    />
                    <div className='font-DMSans font-semibold'>
                      <h1 className='text-black-500 text-base'>
                        {fundDetails?.legalName
                          ? fundDetails?.legalName.replace(/Fund.*$/, 'Fund')
                          : fundDetails?.sName
                          ? fundDetails?.sName.replace(/Fund.*$/, 'Fund')
                          : fundDetails?.sname.replace(/Fund.*$/, 'Fund')}
                      </h1>
                      <p className='text-black-400 text-sm'>{fundDetails?.amcName}</p>
                    </div>
                  </div>
                  <h1 className='font-DMSans font-semibold text-black-500 text-xl text-nowrap'>
                    ₹ {toCurrency(sipAmount)}
                  </h1>
                </div>
                <div>
                  <h1 className='text-black-400 text-base font-normal font-DMSans'>Bank</h1>
                  <div className=' border rounded-[16px] p-4 mt-2'>
                    <div className='flex gap-2'>
                      <div className='rounded-full border border-[#D3DBEC] h-11 w-11 flex justify-center items-center'>
                        {src ? (
                          <img
                            src={src}
                            alt='bank logos'
                            onError={handleError}
                            height={24}
                            width={24}
                          />
                        ) : (
                          <AccountBalance className='text-primary-200 w-5 h-5' />
                        )}
                      </div>
                      <div>
                        <h1 className='font-DMSans font-semibold text-black-500 text-base'>
                          {bankDetails && bankDetails?.accountHolderName}
                        </h1>
                        <p className='font-DMSans'>
                          <span className='text-black-400 text-sm font-semibold'>
                            {bankDetails && bankDetails?.bankName}{' '}
                          </span>
                          <span className='text-black-400 text-sm font-medium'>
                            ending in{' '}
                            <span className='font-semibold'>
                              {' '}
                              {bankDetails &&
                                bankDetails?.accountNo &&
                                bankDetails?.accountNo.slice(-4)}
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogAction
                className='w-full text-white text-sm font-medium font-DMSans bg-primary-500 hover:bg-primary-400'
                onClick={handleXSIPConfirm}
              >
                Set Up
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      ) : (
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetContent
            className='flex flex-col gap-6'
            style={{ borderRadius: '24px 24px 0px 0px' }}
            side={'bottom'}
          >
            <SheetHeader className={'gap-6'}>
              <SheetTitle>
                <div className='relative justify-between'>
                  <div className='text-start'>
                    <h1 className='text-black-500 font-semibold text-xl font-DMSans'>
                      Confirm SIP setup
                    </h1>
                  </div>
                </div>
                <p className='text-black-400 font-normal text-sm text-start font-DMSans'>
                  We will use your preexisting mandate to set up your new SIP.
                </p>
              </SheetTitle>
              <SheetDescription className='text-start flex flex-col gap-6'>
                <div>
                  <h1 className='text-black-400 text-base font-normal font-DMSans'>Bank</h1>
                  <div className='flex border rounded-[16px] justify-between p-4 mt-2'>
                    <div className='flex gap-2'>
                      <img
                        src={`${env.MF_LOGOS_URL}/${fundDetails?.amcCode}.png`}
                        alt={fundDetails?.amcName}
                        className='w-12 h-12 border rounded-lg'
                      />
                      <div className='font-DMSans font-semibold'>
                        <h1 className='text-black-500 text-base'>
                          {' '}
                          {fundDetails?.legalName
                            ? fundDetails?.legalName.replace(/Fund.*$/, 'Fund')
                            : fundDetails?.sName
                            ? fundDetails?.sName.replace(/Fund.*$/, 'Fund')
                            : fundDetails?.sname.replace(/Fund.*$/, 'Fund')}
                        </h1>
                        <p className='text-black-400 text-sm'>{fundDetails?.amcName}</p>
                      </div>
                    </div>
                    <h1 className='font-DMSans font-semibold text-black-500 text-xl text-nowrap'>
                      ₹ {toCurrency(sipAmount)}
                    </h1>
                  </div>
                </div>
                <div>
                  <h1 className='text-black-400 text-base font-normal font-DMSans'>Bank</h1>
                  <div className=' border rounded-[16px] p-4 mt-2'>
                    <div className='flex gap-2'>
                      <div className='rounded-full border border-[#D3DBEC] h-11 w-11 flex justify-center items-center'>
                        {src ? (
                          <img
                            src={src}
                            alt='bank logos'
                            onError={handleError}
                            height={24}
                            width={24}
                          />
                        ) : (
                          <AccountBalance className='text-primary-200 w-5 h-5' />
                        )}
                      </div>
                      <div>
                        <h1 className='font-DMSans font-semibold text-black-500 text-base'>
                          {bankDetails && bankDetails?.accountHolderName}
                        </h1>
                        <p className='font-DMSans'>
                          <span className='text-black-400 text-sm font-semibold'>
                            {bankDetails && bankDetails?.bankName}{' '}
                          </span>
                          <span className='text-black-400 text-sm font-medium'>
                            ending in{' '}
                            <span className='font-semibold'>
                              {' '}
                              {bankDetails &&
                                bankDetails?.accountNo &&
                                bankDetails?.accountNo.slice(-4)}
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SheetDescription>
            </SheetHeader>
            <SheetFooter>
              <SheetClose asChild>
                <Button
                  className='w-full text-white text-sm font-medium font-DMSans bg-primary-500 hover:bg-primary-400'
                  onClick={handleXSIPConfirm}
                  type='submit'
                >
                  Set Up
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
      )}
    </>
  )
}

export default MFPlaceOrder
